import React from "react";
import { Dialog, Paper, Grid, DialogTitle, IconButton, Icon } from "@mui/material";
import Draggable from "react-draggable";

function PaperComponent({ draggableProps, ...paperProps }) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
      bounds="parent"
      {...draggableProps}
    >
      <Paper {...paperProps} />
    </Draggable>
  );
}

function AdjustableDialog({
  dialogProps: { onClose: handleOnClose, ...otherDialogProps },
  paperStyleProps,
  draggableProps,
  dialogTitleStyleProps,
  title,
  children,
}) {
  const onClose = (event, reason) => {
    if (reason === "backdropClick") return;

    handleOnClose(event, reason);
  };

  return (
    <Dialog
      PaperComponent={PaperComponent}
      PaperProps={{
        style: { resize: "both", overflow: "auto", ...paperStyleProps },
        draggableProps,
      }}
      onClose={onClose}
      {...otherDialogProps}
    >
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item xs={11}>
          <DialogTitle
            id="draggable-dialog-title"
            style={{ cursor: "move", ...dialogTitleStyleProps }}
          >
            {title}
          </DialogTitle>
        </Grid>
        <Grid item xs={1} textAlign="center">
          <IconButton onClick={onClose}>
            <Icon>close</Icon>
          </IconButton>
        </Grid>
      </Grid>
      {children}
    </Dialog>
  );
}

export default AdjustableDialog;
