export const baseURL =
  process.env.REACT_APP_BASE_URL || "https://apim-dev.xbrlpoint.com/external/back/api";
export const hubUrl =
  process.env.REACT_APP_HUB_URL ||
  "https://xbrl-signalr-dev-gud3azggamdpewcz.westeurope-01.azurewebsites.net/";
export const iamURL =
  process.env.REACT_APP_IAM_URL || "https://apim-dev.xbrlpoint.com/external/iam/api";
export const logoutIamRedirectUrl =
  process.env.REACT_APP_LOGOUT_IAM_REDIRECT_URL ||
  "https://iam-fe-test.xbrlpoint.com/?destinationRef=xbrl";
export const muixLicence =
  process.env.REACT_APP_MUI_X_LICENCE ||
  "05efe873767019e2f03f88cea3ea4c9cTz0xMDU5MDYsRT0xNzY4NDM1MTk5MDAwLFM9cHJvLExNPXN1YnNjcmlwdGlvbixQVj1RMy0yMDI0LEtWPTI=";
