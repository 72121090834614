/* eslint-disable no-nested-ternary */
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import { MDTypography, MDButton, Authorization } from "components";

function ActionCell({
  value,
  deleteItemPermission,
  deleteItem,
  editItemPermission,
  editItem,
  mapReport,
}) {
  return (
    <MDTypography variant="caption" fontWeight="medium" color="text">
      {editItem ? (
        editItemPermission != null ? (
          <Authorization externalId={editItemPermission} appearance="display">
            <Tooltip enterDelay={1000} enterNextDelay={1000} title="Edit" placement="top">
              <MDButton
                color="dark"
                variant="outlined"
                onClick={(event) => editItem(event, value)}
                iconOnly
              >
                <Icon>edit</Icon>
              </MDButton>
            </Tooltip>
          </Authorization>
        ) : (
          <Tooltip enterDelay={1000} enterNextDelay={1000} title="Edit" placement="top">
            <MDButton
              color="dark"
              variant="outlined"
              onClick={(event) => editItem(event, value)}
              iconOnly
            >
              <Icon>edit</Icon>
            </MDButton>
          </Tooltip>
        )
      ) : null}
      &nbsp;
      {deleteItem ? (
        deleteItemPermission != null ? (
          <Authorization externalId={deleteItemPermission} appearance="display">
            <Tooltip enterDelay={1000} enterNextDelay={1000} title="Delete" placement="top">
              <MDButton
                color="dark"
                variant="outlined"
                onClick={(event) => deleteItem(event, value)}
                iconOnly
              >
                <Icon>delete</Icon>
              </MDButton>
            </Tooltip>
          </Authorization>
        ) : (
          <Tooltip enterDelay={1000} enterNextDelay={1000} title="Delete" placement="top">
            <MDButton
              color="dark"
              variant="outlined"
              onClick={(event) => deleteItem(event, value)}
              iconOnly
            >
              <Icon>delete</Icon>
            </MDButton>
          </Tooltip>
        )
      ) : null}
      &nbsp;
      {mapReport && (
        <Tooltip enterDelay={1000} enterNextDelay={1000} title="Map" placement="top">
          <MDButton
            color="dark"
            variant="outlined"
            onClick={(event) => mapReport(event, value)}
            iconOnly
          >
            <Icon>account_tree</Icon>
          </MDButton>
        </Tooltip>
      )}
    </MDTypography>
  );
}

export default ActionCell;
